<template>
  <v-app dark style="margin-top:20px">
    <!-- Navigation drawer toggle button -->

    <div v-if="isMobile && !currentlyOnRapidPage" class="flex-space-between nav">
      <div>
        <v-img src="/imgs/cube.webp" contain width="30" height="30" style="position:absolute; top: 22px; left:20px;"></v-img>
      </div>
      <v-app-bar-nav-icon class="hamburger" variant="text" style="z-index:1000" @click.stop="drawer = !drawer" v-if="isMobile"></v-app-bar-nav-icon>
    </div>

    <v-navigation-drawer v-model="drawer" app :bottom="isMobile" v-if="isMobile && !currentlyOnRapidPage">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hamburger">
        <v-icon>mdi-close</v-icon>
      </v-app-bar-nav-icon>
      <v-list class="flex-column">
        <v-list-item link href="/">HOME</v-list-item>
        <v-list-item link href="/rapids">RAPIDS</v-list-item>
        <v-list-item link href="/images">IMAGES</v-list-item>
        <v-list-item link href="/about">ABOUT US</v-list-item>
        <v-list-item link href="/contact">CONTACT</v-list-item>
        <template v-if="isLoggedIn">
          <v-list-item link href="/account">Account</v-list-item>
          <v-list-item @click="logout">Logout</v-list-item>
        </template>
        <template v-else>
          <v-list-item link href="/signup">SIGN UP</v-list-item>
          <v-list-item link href="/login">LOGIN</v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="!currentlyOnRapidPage" app color="rgb(12,12,12)" dark class="navbar">
      <div style="display:flex; justify-content: start">
        <v-img src="/imgs/cube.webp" contain class="logo-cube"></v-img>
        <div class="d-flex align-center">
          <v-btn text href="/">HOME</v-btn>
          <v-btn text href="/rapids">RAPIDS</v-btn>
          <v-btn text href="/images">IMAGES</v-btn>
          <v-btn text href="/about">ABOUT US</v-btn>
          <v-btn text href="/contact">CONTACT</v-btn>
        </div>
      </div>
      <v-spacer></v-spacer>
      <template v-if="isLoggedIn">
        <v-menu>
          <template  v-slot:activator="{ on, attrs }" >
            <v-btn text v-bind="attrs" v-on="on" class="account-menu">
              {{ userName }}
              <!-- Make the icon bigger and bolder -->
              <v-icon right x-large style="font-weight: bold; margin-top:-5px; color:white!important;">mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list style="z-index:1000; font-size:16px;">
            <v-list-item link href="/my-rapids">My Rapids</v-list-item>
            <!--  <v-list-item link href="/account">Account</v-list-item> -->
            <v-list-item @click="logout">Logout</v-list-item>
          </v-list>
        </v-menu>

      </template>
      <template v-else>
        <v-btn text href="/signup">SIGN UP</v-btn>
        <v-btn text href="/login">LOGIN</v-btn>
      </template>
    </v-app-bar>

    <div v-if="!currentlyOnRapidPage" class="vertical-sep"></div>

    <v-main class="flex-grow-1">
      <router-view />
    </v-main>
    <notifications group="dark" position="bottom right"/>
  </v-app>
</template>

<script>
import { onAuthStateChanged, getAuth } from 'firebase/auth';

export default {
  name: 'RadBytesLayout',
  data() {
    return {
      drawer: false,
      user: null,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isLoggedIn() {
      return this.user != null;
    },
    userName() {
      return this.user && this.user.displayName ? this.user.displayName : this.user.email;
    },
    currentlyOnRapidPage() {
      return (this.$route.path === '/rapid-exam' || this.$route.path === '/images');
    },
  },
  mounted() {
    const auth = getAuth(); // Access the existing Firebase auth instance
    onAuthStateChanged(auth, (user) => {
      console.log('Auth state changed:', user);
      this.user = user;
    });

  },
  methods: {
    logout() {
      const auth = getAuth(); // Re-access the auth instance for the logout method
      auth.signOut().then(() => {
        this.drawer = false;
        this.user = null;
        this.store.dispatch('updateFirebaseUserId', null);
        this.store.dispatch('updateEmailVerificationRequired', true);
      }).catch(error => {
        console.error('Logout error:', error);
      });
    }
  }
}
</script>



<style>

.vue-notification {
  padding: 20px 15px 20px 15px!important;
  margin-bottom: 20px!important;
  margin-right: 20px!important;
 
  font-size: 19px!important;
 
  color: #ffffff;
  background: black!important;
  border: 1px solid rgba(250,250,250,0.5)!important;
  border-radius: 8px;

 
  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }
 
  &.error {
    background: #E54D42;
    border-left-color: #B82E24;
  }
 
  &.success {
    background: #68CD86;
    border-left-color: #42A85F;
  }
}



.v-list-item {
  margin-top:0px!important;
  padding: 10px 23px 10px 23px;
}

.vertical-sep {
  height: 40px;
}

.nav {
  height: 75px;
  background: rgb(12,12,12);
  background: -moz-linear-gradient(180deg, rgba(12,12,12,1) 0%, rgba(12,12,12,0.85) 85%, rgba(12,12,12,0) 100%);
  background: -webkit-linear-gradient(180deg, rgba(12,12,12,1) 0%, rgba(12,12,12,0.85) 85%, rgba(12,12,12,0) 100%);
  background: linear-gradient(180deg, rgba(12,12,12,1) 0%, rgba(12,12,12,0.85) 85%, rgba(12,12,12,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0C0C0C",endColorstr="#0C0C0C",GradientType=1);
  position:sticky;
  top:0;
  z-index:999;
}

.hamburger {
    right: 10px;
    top: 10px;
    position:absolute;
    z-index:1000;
    width:60px;
    height:60px;
}

.v-navigation-drawer--close {
  display: none!important;
}


.v-navigation-drawer {
  top: 0!important;
  height:300px;
  background: rgb(12,12,12);
  background: -moz-linear-gradient(180deg, rgba(12,12,12,1) 0%, rgba(12,12,12,0.85) 85%, rgba(12,12,12,0) 100%);
  background: -webkit-linear-gradient(180deg, rgba(12,12,12,1) 0%, rgba(12,12,12,0.85) 85%, rgba(12,12,12,0) 100%);
  background: linear-gradient(180deg, rgba(12,12,12,1) 0%, rgba(12,12,12,0.85) 85%, rgba(12,12,12,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0C0C0C",endColorstr="#0C0C0C",GradientType=1);
  box-shadow:none;
  z-index:1000;
}

/* Customizing the color of the hamburger icon */
.v-navigation-drawer .v-btn.v-btn--icon .v-icon {
  color: white; /* Change the color to your desired color */
}

.navbar {
  padding: 10px;
  padding-bottom: 70px;
  background-color: rgb(12,12,12)!important;
  height:80px!important;

}

.logo-cube {
  margin-right: 40px;
  width: 60px;
  height: 60px;
}

::v-deep .v-btn {
  font-size: 16px;
  padding: 20px 17px 20px 17px!important;

}

/* Adjust image margin directly if needed */
::v-deep .v-img {
  margin-right: 8px; /* or any value that fits your design */
}

@media (max-width: 1260px) {
  ::v-deep .v-btn {
    font-size: 15px;
    padding: 18px 15px 18px 15px!important;
  }
  .logo-cube {
    margin-right: 35px;
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 1050px) {
  ::v-deep .v-btn {
    font-size: 14px;
    padding: 17px 14px 17px 14px!important;
  }

  .logo-cube {
    margin-right: 20px;
    width: 45px;
    height: 45px;
  }
}


@media (max-width: 958px) {
  .vertical-sep {
    height:0px;
    transition: all 1s;
  }
}

/* CSS for making list items appear top down */
.flex-column {
  flex-direction: column;
}

/* Add margin to top of list items to create space between them */
.v-list-item {
  margin-top: 10px;
}



</style>
