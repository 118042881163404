import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/Home.vue';
import AboutUs from '../views/AboutUs.vue';
import ContactUs from '../views/ContactUs.vue';
import RapidsPage from '../views/RapidsPage.vue';
import RapidExam from '../views/RapidExam.vue';

import ImagesPage from '../views/ImagesPage.vue';

import MyRapids from "../views/MyRapids.vue";
import LoginPage from '../views/Login.vue';
import SignupPage from '../views/Signup.vue';
import ReviewResultsPage from '../views/ReviewResultsPage.vue';
import VerifyEmailPage from '../views/VerifyEmail.vue';
import ForgotPasswordPage from "../views/ForgotPassword.vue";
import ResetPasswordPage from "../views/ResetPassword.vue";

import AdminDashPage from "../views/admin/AdminDash.vue";
import MessagesPage from "../views/admin/MessagesPage.vue";


import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { 
  userIsAdmin
} from '@/api.js';

import store from '../store';




Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: "RadBytes"
    }
  },
  {
    path: '/images',
    name: 'ImagesPage',
    component: ImagesPage,
    meta: {
      title: "Educational Radiology Images - RadBytes"
    }
  },
  {
    path: '/admin',
    name: 'AdmingDash',
    component: AdminDashPage,
    meta: {
      title: 'Admin Dashboard Page - RadBytes',
      requiresAuth: true,
      requiresVerifiedEmail: true,
      onlyAdminsAllowed: true
    }
  },
  {
    path: '/admin/messages-page',
    name: 'MessagesPage',
    component: MessagesPage,
    meta: {
      title: 'Admin Messages Page - RadBytes',
      requiresAuth: true,
      requiresVerifiedEmail: true,
      onlyAdminsAllowed: true
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPasswordPage,
    meta: {
      title: "Reset Password - RadBytes"
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordPage,
    meta: {
      title: "Forgot Password - RadBytes"
    }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutUs,
    meta: {
      title: "About Us - RadBytes"
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactUs,
    meta: {
      title: "Contact Us - RadBytes"
    }
  },
  {
    path: '/rapids',
    name: 'rapids',
    component: RapidsPage,
    meta: {
      title: "Rapids - RadBytes"
    }
  },
  {
    path: '/rapid-exam',
    name: 'RapidExam',
    component: RapidExam,
    meta: { 
      requiresAuth: true,
      requiresVerifiedEmail: true,
      title: "Rapid Exam - RadBytes",
      checkPaid: true
    }
  },
  {
    path: '/my-rapids',
    name: "MyRapids",
    component: MyRapids,
    meta: {
      requiresAuth: true,
      requiresVerifiedEmail: true,
      title: "Your Rapid Packages - RadBytes"
    }
  },
  {
    path: '/review-results-page',
    name: 'ReviewResultsPage',
    component: ReviewResultsPage,
    meta: { 
      requiresAuth: true,
      title: "Review Exam Results - RadBytes"
    }
  },
  {
    path: '/verify-email',
    name: 'VerifyEmailPage',
    component: VerifyEmailPage,
    meta: { 
      requiresAuth: true,
      title: "Verify Email - RadBytes" 
    }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: {
      title: "Login - RadBytes"
    }
  },
  {
    path: '/signup',
    name: 'SignupPage',
    component: SignupPage,
    meta: {
      title: "Sign Up - RadBytes"
    }
  }
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'RadBytes';
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresVerifiedEmail = to.matched.some(record => record.meta.requiresVerifiedEmail); // Corrected here
  const onlyAdminsAllowed = to.matched.some(record => record.meta.onlyAdminsAllowed);

  onAuthStateChanged(auth, async user => {


    if (requiresAuth && !user) {
      next('/login');
    } else if (user && requiresVerifiedEmail && !user.emailVerified) {
      next('/verify-email');
    } else {
      if(user) {
        store.dispatch('updateUserEmail', user.email);
        store.dispatch('updateFirebaseUserId', user.uid);
        if(!user.emailVerified) {
          store.dispatch('updateEmailVerificationRequired', true);
        } else {
          store.dispatch('updateEmailVerificationRequired', false);
        }

        if(onlyAdminsAllowed) {
          try {
            // Check if the user is an admin before proceeding
            const isAdmin = await userIsAdmin({ "email": user.email });
            if (isAdmin) {
              next(); // Proceed if the user is an admin
              return;
            } else {
              next('/'); // Redirect to home if the user is not an admin
            }
          } catch (error) {
            console.error('Error checking admin status:', error);
            next('/'); // Handle errors by redirecting to home or an error page
          }
        }
      } else {
        store.dispatch('updateFirebaseUserId', null);
        store.dispatch('updateUserEmail', null);
      }
      next();
    }
  });


});



export default router;
